﻿$monarch-yellow: #d59038;
$monarch-yellow-10: #d5903810;
$monarch-yellow-20: #d5903820;
$monarch-yellow-30: #d5903830;
$monarch-yellow-soft: #cf8d36EA;
$monarch-lightgreen: #50645e;
$monarch-lightgreen-10: #50645e10;
$monarch-lightgreen-20: #50645e20;
$monarch-lightgreen-30: #50645e30;
$monarch-lightgreen-50: #50645e50;
$monarch-lightgreen-70: #50645e70;
$monarch-lightgreen-A0: #50645eA0;
$monarch-darkgreen: #3f4f4a;
$monarch-darkgreen-10: #3f4f4a10;
$monarch-darkgreen-20: #3f4f4a20;
$monarch-darkgreen-30: #3f4f4a30;
$monarch-darkgreen-50: #3f4f4a50;
$monarch-darkgreen-70: #3f4f4a70;
$monarch-darkgreen-A0: #3f4f4aA0;
$monarch-gray: #696a6a;
$monarch-purple: #502036;
$monarch-blue: #01496f;
$monarch-blue-70: #01496f70;


$screen-phone-min: 0px;
$screen-phone-max: 767px;
$screen-tablet-min: 768px;
$screen-tablet-max: 991px;
$screen-laptop-min: 992px;
$screen-laptop-max: 1600px;
$screen-lgscreen-min: 1601px;
$screen-lgscreen-max: 9999px;