@import '../Variables.scss';

:global .navbar {
    background-color: white;
}

.brand {
    order: 1;
}

.brand img {
    height: 4rem;
    width: 6rem;
    object-fit: contain;
}

.burger {
    border-color: $monarch-lightgreen;
}

.menuDisplay {
    margin-right: 0.5em;
    order: 2;
}

.signIn {
    order: 3;
}

.propertiesWrapper {
    display:flex;
    flex-direction: column;
    width: 140px * 7;
}

.propertiesContainer{
    display:flex;
    flex-flow: column wrap;
    max-height: 500px;
}

.propertiesContainer > *{
    width: 140px;
}

.showOnMobile{
    display: none;
}

.icon{
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
}


.fauxItem {
    text-decoration: none;
    padding:8px;
    
}

.fauxItem :hover{
    background-color: $monarch-yellow;
    cursor:pointer;
}

@media only screen and (max-width: 1023px) {
    .propertiesContainer {
        flex-direction: row;
    }

    .propertiesWrapper {
        width: 100%;
    }
}

@media only screen and (max-width: 992px) {
    .hideOnMobile {
        display: none;
    }

    .showOnMobile {
        display: block;
    }

    .burger {
        order: 1;
    }

    .brand {
        order: 2;
    }

    .signin {
        order: 3;
    }

    .menuDisplay {
        order: 4;
        max-height: 70vh;
        overflow-y: auto;
    }
}

@media only screen and (max-width: 750px) {
    .brand img {
        height: 3rem;
    }
}


.linkWrapper {
    display: flex;
    width: 100%;
    white-space: nowrap;
    padding: 0.25rem 0.5rem;
}


:global .navbar .nav-link {
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 3.5rem;
    padding: 0 1rem !important;
}

:global .navbar .dropdown-item {
    padding: 0;
}

:global .navbar .dropdown-menu {
    box-shadow: 2px 2px 2px lightgray;
}

:global .navbar .nav-link:hover,
:global .navbar .nav-link.active,
:global .navbar .nav-item.dropdown.show,
:global .navbar .dropdown-toggle.nav-link.active,
:global .navbar .dropdown-item:hover {
    background-color: $monarch-yellow !important;
}