@import '../Variables.scss';

:global .navbar .nav-link{
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 3.5rem;
    padding: 0 1rem !important;
}

:global .navbar .dropdown-item{
    padding: 0;
}

:global .navbar .dropdown-menu{
    box-shadow: 2px 2px 2px lightgray;
}

:global .navbar .nav-link:hover,
:global .navbar .nav-link.active,
:global .navbar .nav-item.dropdown.show,
:global .navbar .dropdown-toggle.nav-link.active,
:global .navbar .dropdown-item:hover
{
    background-color: $monarch-yellow !important;
}

:global .navbar .dropdown-item.active{
    background-color: transparent;
    color: black;
}

.bold{
    font-weight: bold;
}

.dropDownHeader, .dropDownHeader:hover, .dropDownHeader:active{
    background-color:lightgray;
    cursor: default;
    text-align: center;
    font-weight: bold;
}

.linkWrapper{
    display: flex;
    width: 100%;
    white-space: nowrap;
    padding: 0.25rem 0.5rem;
}

@media (max-width: $screen-tablet-max){
    :global .navbar .nav-link{
        line-height: 2.5rem;
    }
}