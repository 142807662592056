@import './components/Variables.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    background-color: white;
    flex: 1;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}